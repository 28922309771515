import { useState } from 'react'
import SimpAPI from '../assets/the-simpsons-quote-api.jpg'
import Covid from '../assets/covid19stats.jpg'
import Amber from '../assets/ambertripp.jpg'
import Deck from '../assets/ptcg.jpg'
import Match from '../assets/matchingGame.jpg'
import QuoteGen from '../assets/simpsonquote.jpg'
import Todo from '../assets/todolist.jpg'
import Git from '../assets/github.png'
import Open from '../assets/open.png'

const Projects = () => {


  


  
    
    return(
        <div id='projects' className='components'>



          
          <div className='project-container'>
            <ul>
              <li className='left'>
  
              
                <div className='project-content'>
                  <h1>The Simpsons Quote API</h1>
                  <p>A public facing API built using express and mongoDB to serve up random quotes from The Simpsons.</p>
                  <div className='links'>
                      <a className='project-btns' href='https://github.com/tripptofan/the-simpsons-quote-api'>
                       View Source
                      
                      </a>
                      <a className='project-btns' href='https://the-simpsons-quote-api.herokuapp.com/'>
                      Open
                      </a>
                      </div> 
                  </div>
                  <img src={SimpAPI} alt='The Simpsons Quote API'/>
                  </li>
              <hr/>
              <li className='right' >   
           
                   
                      
              <img src={Covid} alt='Covid-19 Stats Tracker'/>
                <div className='project-content'>
                  <h1>Covid-19 Stats Tracker</h1>
                  <p>A simple web-app that allows users to input a country of interest to view daily updated information regarding the ongoing pandemic.</p>
                  <div className='links'>
                      <a className='project-btns' href='https://github.com/tripptofan/covid19-stats'>
                        View Source
                      </a>
                      <a className='project-btns' href='http://voiceless-manager.surge.sh/'>
                        Open
                      </a>
                      </div> 
                  </div>
                  </li>
              <hr/>
              <li className='left'>   

                    
                      
                <div className='project-content'>
                  <h1>Graphic Designer Portfolio</h1>
                  <p>An active graphic designer's portfolio built with Wordpress.</p>
                  <div className='links'>
                      <a className='project-btns'  href='http://amber-tripp.com/'>
                        Open
                      </a>
                      </div> 
                  </div>
                  <img src={Amber} alt='Amber Tripp Graphic Design'/>
                  </li>
              <hr/>
              <li className='right' >  

                    
                      
                <img src={Deck} alt='PTCG Deck Builder'/>
                <div className='project-content'>
                  <h1>PTCG Deck Builder</h1>
                  <p>A CRUD app made in React for building simulated decks for the Pokemon Trading Card Game.  Decks can be saved to be loaded back up later.</p>
                  <div className='links'>
                      <a className='project-btns'  href='https://github.com/tripptofan/ptcg-deck-builder'>
                        View Source
                      </a>
                      <a className='project-btns'  href='http://friendly-science.surge.sh/'>
                        Open
                      </a>
                      </div> 
                  </div>                 
                  </li>
              <hr/>
              <li className='left'>   
      
                   
                <div  className='project-content'>
                  <h1>Matching Game</h1>
                  <p>A simple card matching game using my own illustrations.</p>
                  <div className='links'>
                      <a className='project-btns'  href='https://github.com/tripptofan/card-matching-game'>
                       View Source
                      </a>
                      <a className='project-btns'  href='http://sticker-matching-game.s3-website.us-east-2.amazonaws.com/'>
                        Open
                      </a>
                      </div>
                  </div>
                  <img src={Match} alt='Card Matching Game'/>
                  </li>
              <hr/>
              <li className='right' >

                    
              <img src={QuoteGen} alt='The Simpsons Quote Generator'/>  
                <div  className='project-content'>
                  <h1>The Simpsons Quote Generator</h1>
                  <p>Generates a random quote and displays it with the character who said it.</p>
                  <div className='links'>
                      <a className='project-btns'  href='https://github.com/tripptofan/simpsons-quote-generator'>
                        View Source
                      </a>
                      <a className='project-btns'  href='http://limping-idea.surge.sh/'>
                        Open
                      </a>
                      </div>
                 </div>                 
                  </li>
              <hr/>
              <li className='left' >   
         
                   
                <div  className='project-content'>
                  <h1>Todo List</h1>
                  <p>Create, save, and delete items on a todo list.</p>
                  <div className='links'>
                      <a className='project-btns'  href='https://github.com/tripptofan/todo-list'>
                        View Source
                      </a>
                      <a className='project-btns'  href='http://todo-list-react-app.s3-website.us-east-2.amazonaws.com/'>
                       Open
                      </a>
                      </div>
                  </div>
                  <img src={Todo} alt='Todo List'/>
                  </li>
            </ul>

          </div>


        </div>
    )
  }

  export default Projects