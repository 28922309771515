import Email from '../assets/email.png'
import Linkedin from '../assets/linkedin.png'
import Git from '../assets/github.png'
import Resume from '../assets/JasonTrippDev-Resume.pdf'


const Contact = () => {

    return(
        <div id='contact' className='components'>


          <div className='social-links'>
            <a  href="https://github.com/tripptofan"
              target="_blank"
              rel="noopener noreferrer">
                <img src={Git} alt='Github'/>
                </a>
                <a
              href="https://www.linkedin.com/in/jason-tripp-4695965a/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Linkedin} alt='Linkedin' />
              </a>
            <a href="mailto:jasontrippdev@gmail.com"><img src={Email} alt='Email'/></a>
        </div>
        <a className='resume' href={Resume} download >
    <button className='resume-btn'><i class="fa fa-download"></i> &nbsp; Download CV</button>
</a>

        </div>
    )
  }
  
export default Contact  