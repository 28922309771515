import './App.css';
import { Route, Switch, useLocation, NavLink } from 'react-router-dom'
import { useTransition, animated } from 'react-spring'
import Home from './components/Home'
import Projects from './components/Projects'
import Contact from './components/Contact'
import homeIcon from './assets/homeicon.png'



function App() {

const location = useLocation()
console.log(location)
const transitions = useTransition(location, location => location.pathname, {
  from: { opacity: 0, transform: 'translate3d(100%, 0, 0)' },
  enter: { opacity: 1, transform: 'translate3d(0%, 0, 0)' },
  leave: { opacity: 0, transform: 'translate3d(-50%, 0, 0)' }
})


  return  transitions.map(({ item: location, props, key }) => (

    <div className='App'>

    <animated.div key={key} style={props} className='component-container'>
      <Switch location={location}>
        <Route path='/' exact component={Home} />
        <Route path='/projects'  component={Projects} />
        <Route path='/contact'  component={Contact} />
      </Switch>
    </animated.div>

    <div className='nav-buttons'>
      <NavLink exact to='/' activeClassName='selected' ><button><img src={homeIcon} alt='home'/></button></NavLink>
      <NavLink exact to='/projects'  activeClassName='selected'><button>Projects</button></NavLink>
      <NavLink exact to='/contact' activeClassName='selected' ><button>Contact</button></NavLink>
    </div>

    </div>
  ))
}

export default App;
