import React from 'react'

const Home = () => {


  // const items = range(2)
  // const interp = i => r => `translate3d(0, ${5 * Math.sin(r + (i * 2 * Math.PI) / 2.5)}px, 0)`
  // const { radians } = useSpring({
  //   to: async next => {
  //     while (1) await next({ radians: 2 * Math.PI })
  //   },
  //   from: { radians: 0 },
  //   config: { duration: 3500 },
  //   reset: true,
  // })

return(

<div id='home' className='components'>


  <div className='component-content'>

    <div  className='title'  >
      <h1>Jason Tripp</h1>
      <h2 >Full-Stack Developer</h2>
    </div>
        
    <div className='component-info'  >
      <ul>
        <li>HTML</li>
        <li>CSS</li>
        <li>Javascript</li>
        <li>React</li>
        <li>Node</li>
        <li>Wordpress</li>
        <li>MongoDB</li>
        <li>Express</li>
        <li>API</li>
        <li>Git</li>
        <li>ThreeJS</li>
        <li>AWS</li>
      </ul>
    </div>

  </div>
        

</div>
)
}

  export default Home;